define(['app'], (app) => () => {
  const comp = {
  };

  const _select = {
    closeModalButton: '.foundationFinderMultiBrand_modal_close'
  };

  comp.init = (el) => {
    comp.element = el;
    comp.closeButton = comp.element.querySelector(_select.closeModalButton);
    comp.bind();

    return comp;
  };

  comp.bind = () => {
    comp.closeButton &&  comp.closeButton.addEventListener('click', comp.closeMoreInfoModal);
  };

  comp.closeMoreInfoModal = () => {
    app.publish('modal/close', true);
  };

  return comp;
});
